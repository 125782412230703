import React from "react"
import Topbar from "../../components/organisms/topbar"
import CustomLayout from "../../Layouts/Layout"
import HomeContent from "./Home"
import "./style.scss"

export default function Home() {
  return (
    <div
      className="home-page"
      style={{
        background: "#fafafa !important",
      }}
    >
      <Topbar />
      <CustomLayout selectedPage={<HomeContent />} />
    </div>
  )
}
